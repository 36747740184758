import LockScreenData from "../components/lockscreen/data.json";

export const loadCategoryWidgets = () => {
  let urls = [
    "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/categories/widgets/sports-left.webp",
    "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/categories/widgets/play-left.webp",
    "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/categories/widgets/shop-left.webp",
    "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/categories/widgets/trends-left.webp",
    "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/categories/widgets/ent-left.webp",
    "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/categories/widgets/sports-right.webp",
    "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/categories/widgets/play-right.webp",
    "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/categories/widgets/shop-right.webp",
    "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/categories/widgets/trends-right.webp",
    "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/categories/widgets/ent-right.webp",
  ];
  return urls.map((url, index: any) => {
    return <link key={index} href={url} rel="preload" as="image"></link>;
  });
};

export const loadLandingTileImages = () => {
  let images = [];
  images.push(
    <link
      href="glance_loading.gif"
      rel="preload"
      as="image"
      media="(max-width: 1060px)"
    ></link>
  );
  for (let i = 1; i < frameCount; i = i + 2) {
    images.push(
      <link
        href={currentFrame(i)}
        rel="preload"
        as="image"
        media="(max-width: 1060px)"
      ></link>
    );
  }
  return images.map((item, key) => {
    return item;
  });
};

const frameCount = 89;
const currentFrame = (index: number | string) => {
  let idx = index + "";
  if (idx.length === 1) {
    idx = "000" + idx;
  } else if (idx.length === 2) {
    idx = "00" + idx;
  } else if (idx.length === 3) {
    idx = "0" + idx;
  }
  return `${LockScreenData.landingTileData.imagesDesktopBasePath}3d_mobile${idx}.webp`;
};

export const sectionIds = [
  {
    section: "zero",
    scroll: [""],
  },
  {
    section: "first",
    scroll: [],
  },
  {
    section: "second",
    scroll: [],
  },
  {
    section: "third",
    scroll: [],
  },
  {
    section: "fourth",
    scroll: ["", "", "", ""],
  },
  {
    section: "fifth",
    scroll: [],
  },
  {
    section: "sixth",
    scroll: ["", "", ""],
  },
  {
    section: "seventh",
    scroll: [],
  },
  {
    section: "eight",
    scroll: ["", ""],
  },
  {
    section: "nine",
    scroll: [],
  },
  {
    section: "ten",
    scroll: [],
  },
  {
    section: "eleven",
    scroll: [],
  },
  {
    section: "twelve",
    scroll: [],
  },
  {
    section: "thirteen",
    scroll: [],
  },
  {
    section: "fourteen",
    scroll: ["", ""],
  },
];

export const largeTextCss: any = {
  enBold: `
  font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 55px;
    line-height: 65px;
    letter-spacing: 1px;
    color: #ffffff;
    @media screen and (min-width: 1060px) and (max-width: 1260px) {
      font-size: 50px;
      line-height: 60px;
    }
    @media (max-width: 992px) {
      font-size: 46px;
      line-height: 52px;
    }
  `,
  enNormal: `
  font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 0.03em;
    color: #ffffff;
    @media screen and (min-width: 1060px) and (max-width: 1260px) {
      font-size: 38px;
      line-height: 48px;
    }
    @media (max-width: 992px) {
      font-size: 25px;
      line-height: 34px;
    },
    
  `,
  idBold: `
  
  font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 1px;
    color: #ffffff;
    @media screen and (min-width: 1060px) and (max-width: 1260px) {
      font-size: 30px;
      line-height: 40px;
    }
    @media (max-width: 992px) {
      font-size: 26px;
      line-height: 32px;
    }
  
  
  `,
  idNormal: `
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.03em;
  color: #ffffff;
  @media screen and (min-width: 1060px) and (max-width: 1260px) {
    font-size: 26px;
    line-height: 38px;
  }
  @media (max-width: 992px) {
    font-size: 18px;
    line-height: 24px;
  }
  `,
  jpBold: `
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 1px;
    color: #ffffff;
    @media screen and (min-width: 1060px) and (max-width: 1260px) {
      font-size: 36px;
      line-height: 44px;
    }

    @media screen and (min-width: 520px) and (max-width: 992px) {
      font-size: 32px;
      line-height: 40px;
    }

    @media screen and (min-width: 380px) and (max-width: 520px) {
      font-size: 28px;
      line-height: 36px;
    }

    @media screen and (min-width: 280px) and (max-width: 380px) {
      font-size: 26px;
      line-height: 34px;
    }
  `,
};
