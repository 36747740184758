import styled from "styled-components";

export const BlackPageLayer = styled.div<any>`
  /* width: 100vw; */
  height: auto;
  min-height: ${(props) => props.minHeight || "auto"};
  /* background: rgba(0, 0, 0, 0.9); */
  background: ${(props) => props.background || "#1b1018"};
  position: relative;
`;

export const Popup = styled.div<any>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 999;

  .popup-container {
    width: 40vw;
    /* height: 60vh; */
    background: white;
    border-radius: 16px;
    padding: 2vh 2vw;
    display: flex;
    flex-direction: column;

    .close-icon {
      align-self: flex-end;
    }

    .close-icon img {
      width: 15px;
      cursor: pointer;
    }

    .popup-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left-sec {
        display: flex;
        align-items: center;
        .warning-logo {
          width: 30px;
        }

        .title {
          margin: 0px 0px 0px 12px;
        }
      }
    }

    @media (max-width: 768px) {
      width: 90vw;
      padding: 2vh;
    }
  }
`;
